export default (value: any, ctx: any): boolean => {
  // Check if password contains at least 10 characters
  if (value.length < 10) {
    return ctx.createError({
      message: "Field must contain at least 10 characters",
    });
  }

  // Check if password contains at least one uppercase letter
  if (!/[A-Z]/.test(value)) {
    return ctx.createError({
      message: "Field must contain at least one uppercase letter",
    });
  }

  // Check if password contains at least one symbol
  if (!/[a-zA-Z]/.test(value)) {
    return ctx.createError({
      message: "Field must contain at least one symbol",
    });
  }

  // Check if password contains at least one number
  if (!/\d/.test(value)) {
    return ctx.createError({
      message: "Field must contain at least one number",
    });
  }

  // Check if password contains at least one special character
  if (!/[^a-zA-Z0-9]/.test(value)) {
    return ctx.createError({
      message: "Field must contain at least one special character",
    });
  }

  return true;
};
